<template>
  <section>
    <TogglePanel
      v-if="headerFields"
      :title="$tc('rich_text.heading', 1)"
      :icon="{
        collapse: 'fa-caret-square-o-up',
        expand: 'fa-caret-square-o-down'
      }"
      :collapsed="!panels.header"
      persistent="toggle_table_panel_header"
      @changed="panels.header = !$event"
    >
      <div style="padding: 0 0 0 5px">
        <TogglePanel
          :title="`${$t('title')}`"
          :icon="{
            collapse: 'fa-caret-square-o-up',
            expand: 'fa-caret-square-o-down'
          }"
          :collapsed="!panels.title"
          persistent="toggle_table_panel_header_title"
          @changed="panels.title = !$event"
        >
          <div class="form-group form-group-sm">
            <label class="label-inline" for="title">{{ $t("text") }}</label>
            <div class="input-group">
              <input
                :placeholder="`${$t('text')}`"
                class="form-control"
                v-model="titleText"
                ref="inpTitle"
              />
              <div
                class="input-group-addon btn"
                @click.stop.prevent="titleText = ''"
              >
                <i class="fa fa-close"></i>
              </div>
            </div>
          </div>
          <ControlStyleProperties v-model="titleStyle" />
        </TogglePanel>
        <TogglePanel
          :title="`${$t('subtitle')}`"
          :icon="{
            collapse: 'fa-caret-square-o-up',
            expand: 'fa-caret-square-o-down'
          }"
          :collapsed="!panels.subTitle"
          persistent="toggle_table_panel_header_subtitle"
          @changed="panels.subTitle = !$event"
          style="margin-top: -5px"
        >
          <div class="form-group form-group-sm">
            <label class="label-inline" for="subTitle">{{
              $t("subtitle")
            }}</label>
            <div class="input-group">
              <input
                :placeholder="`${$t('text')}`"
                class="form-control"
                v-model="subTitleText"
                ref="inpSubTitle"
              />
              <div
                class="input-group-addon btn"
                @click.stop.prevent="subTitleText = ''"
              >
                <i class="fa fa-close"></i>
              </div>
            </div>
          </div>
          <ControlStyleProperties v-model="subTitleStyle" />
        </TogglePanel>
      </div>
    </TogglePanel>
    <!-- important while in use by the synoptic -->
    <div v-else style="margin-top: 10px"></div>
    <ControlStyleProperties
      style="margin-top: 10px"
      v-model="tableCellStyle"
      borderLabel="synoptic.grid_line"
      :title="$t(title == 'panel_properties' ? 'table' : 'specific_properties')"
      :togglePanelIcons="{
        collapse: 'fa-caret-square-o-up',
        expand: 'fa-caret-square-o-down',
        before: title == 'panel_properties' ? '' : 'fa fa-gears'
      }"
    >
      <template #after>
        <div style="margin: 20px 0 10px 0">
          <label for="">{{ $tc("style") }}</label>
          <div class="inner-panel">
            <div
              v-for="(item, ix) in tableClasses"
              :key="ix"
              class="clicable form-group"
              @click.stop.prevent="toggleTableClass(ix)"
            >
              <i class="fa fa-check-square-o" v-if="item.value"></i>
              <i class="fa fa-square-o" v-else></i>
              <span> {{ item.title }}</span>
            </div>
          </div>
        </div>
        <div style="margin: 20px 0 10px 0" v-if="headerFields">
          <label for="">{{ $tc("download") }}</label>
          <div class="inner-panel">
            <div
              class="clicable form-group"
              @click.stop.prevent="downloadCSV = !downloadCSV"
            >
              <i
                :class="downloadCSV ? 'fa fa-check-square-o' : 'fa fa-square-o'"
              />
              <span> {{ $t("titles.download_as_CSV") }}</span>
            </div>
            <div
              class="clicable form-group"
              @click.stop.prevent="downloadXLS = !downloadXLS"
            >
              <i
                :class="downloadXLS ? 'fa fa-check-square-o' : 'fa fa-square-o'"
              />
              <span> {{ $t("titles.download_as_XLS") }}</span>
            </div>
          </div>
        </div>
      </template>
    </ControlStyleProperties>
    <TogglePanel :title="$t('dataset')" v-if="datasetFields">
      <div
        v-if="!datasetDataPicker"
        class="text-center"
        style="margin-top: -5px"
      >
        <div
          class="btn btn-xs btn-primary"
          :title="$t('add_new_data')"
          @click.stop.prevent="datasetDataPicker = !datasetDataPicker"
        >
          {{ $t("add_new_data") }}
        </div>
      </div>
      <ControlDataSelector
        v-if="datasetDataPicker"
        :connectorId="connectorId"
        :label="$tc('data', 1)"
        :allowedTypes="['bool', 'float', 'int', 'string']"
        :exclude="dataSetIdList"
        @input="addDatasetDataId($event)"
      >
        <template #extra_label>
          <div
            class="btn btn-xs pull-right"
            @click.stop.prevent="datasetDataPicker = !datasetDataPicker"
          >
            <i v-if="datasetDataPicker" class="fa fa-close" />
            <span
              v-else
              :title="$t('add')"
              class="btn btn-xs small text-primary"
            >
              {{ $t("add") }}
              <i class="fa fa-plus"></i>
            </span>
          </div>
        </template>
      </ControlDataSelector>
      <div v-if="dataSetDataList.length">
        <label style="width: 100%">
          <DataDisplayLabelToggle />
          {{ $t("data_list") }}
        </label>
        <draggable class="list-group" v-model="dataSetDataList">
          <div v-for="(item, ix) in dataSetDataList" v-bind:key="ix">
            <div
              class="list-group-item item group-item"
              :title="`${item.clp_id}-${
                (item.device &&
                  item.device.connector &&
                  item.device.connector.name) ||
                ''
              }\n\t→${(item.device && item.device.id) || ''}-${
                (item.device && item.device.name) || ''
              }\n\t\t→${item.id}-${item.name || ''}\n\t\t${
                item.identity_embedded_app || ''
              }
            `"
            >
              <div class="move-item">
                <i class="glyphicon glyphicon-option-vertical"></i>
                <div class="dataset-item-name">
                  {{ getItemName(item) }}
                </div>
                <span class="item-buttons pull-right">
                  <span
                    class="btn btn-xs"
                    :class="isSimulating(item.id) ? 'text-orange' : ''"
                    v-on:click.prevent.stop="toggleSimulation(item.id)"
                  >
                    <i class="fa fa-history hidden-sm hidden-xs"></i>
                  </span>
                  <span
                    class="btn btn-xs"
                    v-on:click.prevent.stop="delDatasetDataId(ix)"
                  >
                    <i class="fa fa-trash"></i>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </draggable>
        <div class="dataset-columns">
          <div class="simple-label">{{ $t("show") }}</div>
          <div
            class="item clicable"
            @click.stop.prevent="dataSetSequenceColumn = !dataSetSequenceColumn"
          >
            <i class="fa fa-check-square-o" v-if="dataSetSequenceColumn"></i>
            <i class="fa fa-square-o" v-else></i>
            <span> {{ $t("row_number") }}</span>
          </div>
          <div
            class="item clicable"
            @click.stop.prevent="dataSetTimeColumn = !dataSetTimeColumn"
          >
            <i class="fa fa-check-square-o" v-if="dataSetTimeColumn"></i>
            <i class="fa fa-square-o" v-else></i>
            <span> {{ $t("datetime") }}</span>
          </div>
          <div class="form-group form-group-sm" style="margin-top: 10px">
            <div class="simple-label">{{ $t("missing_values") }}</div>
            <select v-model="dataSetMissingValues" class="form-control">
              <option value="last_value">{{ $t("last_value") }}</option>
              <option value="linear_interpolation">
                {{ $t("linear_interpolation") }}
              </option>
              <option value="leave_them_empty">
                {{ $t("leave_them_empty") }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group form-group-sm target-cell">
          <div
            class="input-group"
            :class="isDataSetAddressValid ? '' : 'has-error'"
          >
            <div
              class="input-group-addon"
              :class="cellSearch ? 'text-orange' : ''"
            >
              {{ $t("target_cell") }}
            </div>
            <input
              type="text"
              class="form-control text-center"
              v-model="dataSetAddress"
              :disabled="cellSearch"
              :placeholder="currentAddress"
            />
            <div
              v-if="currentAddress && !dataSetAddress"
              class="input-group-addon btn text-black"
              @click.stop.prevent="dataSetAddress = currentAddress"
            >
              <i class="fa fa-check"></i>
            </div>
            <div
              v-else
              class="input-group-addon btn"
              :class="cellSearch ? 'text-orange' : ''"
              @click.stop.prevent="toggleCellSearch"
            >
              <i class="fa fa-crosshairs"></i>
            </div>
          </div>
        </div>
        <div
          class="small text-danger text-center"
          v-if="!isDataSetAddressValid && dataSetAddress"
        >
          {{ $t("not_enough_room") }}
        </div>
      </div>
    </TogglePanel>
    <!-- important while in use by the synoptic -->
    <!-- <div v-else style="margin-top:10px;"></div> -->
    <TogglePanel
      title="selection"
      v-if="currentCell"
      style="margin-top: 10px"
      persistent="toggle_table_current_cell"
    >
      <template #title>
        <span style="vertical-align: top">
          {{ $tc("cell", 1) }}
          <b
            class="cell-edit-button"
            @click.stop.prevent="toggleCellForm"
            :title="` ${$t('rich_text.cell_properties')} ${currentAddress} `"
          >
            {{ currentAddress }}
            <i class="fa fa-edit"></i>
          </b>
        </span>
      </template>
      <CellForm
        v-model="currentCell"
        :address="currentAddress"
        :dataSetConfig="isDataSetAddressValid ? dataSetConfig : null"
        :open="cellEdit"
        ref="cellForm"
      />
    </TogglePanel>
  </section>
</template>

<script>
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ControlDataSelector from "@/components/synoptic/property-editor/controls/control-data-selector.vue";
import ControlStyleProperties from "@/components/synoptic/property-editor/controls/control-style-properties.vue";
import CellForm from "@/components/control-sidebar/property-editors/cell-form.vue";
import DataDisplayLabelToggle from "@/components/editor/data-display-label-toggle.vue";
import draggable from "vuedraggable";

const defFormat = {
  bool: "",
  string: "%s",
  float: "%.2f",
  int: "%d"
};

const defCell = () => {
  return {
    value: "",
    data_id: "",
    data_source: "constant",
    state_list: null,
    format: "",
    default: "",
    style: {
      color: "#333",
      "background-color": "#fff",
      "box-shadow": "none",
      "text-align": "center",
      "font-family": "Source Sans Pro",
      "font-size": "11pt",
      "font-style": "normal",
      "font-weight": "normal",
      "text-decoration": "none",
      padding: "0px 0px 0px 0px",
      width: "auto",
      "white-space": "nowrap"
    }
  };
};

const defTableStyle = () => {
  return {
    classes: {
      "table-bordered": true,
      "table-condensed": true,
      "table-striped": true,
      "table-hover": true
    },
    cssVars: {
      "--border-color": "gray",
      "--border-width": "1px"
    }
  };
};

export { defCell };

export default {
  name: "DetailFormTable",
  props: {
    title: {
      type: String,
      required: false,
      default: "panel_properties"
    },
    headerFields: {
      type: Boolean,
      required: false,
      default: true
    },
    datasetFields: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    TogglePanel,
    ControlDataSelector,
    ControlStyleProperties,
    CellForm,
    DataDisplayLabelToggle,
    draggable
  },
  data() {
    return {
      busy: false,
      info: null,
      historySearch: false,
      panels: {
        header: false,
        title: false,
        subTitle: false,
        table: false,
        cell: false
      },
      dataSources: ["constant", "data", "system"],
      expressionAppend: true,
      cellSearch: false,
      cellEdit: false,
      datasetDataPicker: false,
      datasetCollapsed: true
    };
  },
  computed: {
    connectorId() {
      return this.$store.getters["dashboard/dashboardEquipmentId"] || 0;
    },
    draft() {
      return this.$store.getters["dashboard/draft"] || null;
    },
    panel() {
      let currentDraftPanel =
        this.$store.getters["dashboard/currentDraftPanel"] || null;
      if (!currentDraftPanel) return null;
      if (currentDraftPanel.template == "DashboardTablePanel") {
        return currentDraftPanel;
      } else {
        if (!this?.info?.panelName) return null;
        return (
          (this.draft &&
            this.draft?.template &&
            (this.draft?.template?.panels || []).find(
              (i) => i.name == this.info.panelName
            )) ||
          null
        );
      }
    },
    control() {
      if (this.panel) {
        return this?.info?.control || null;
      } else {
        return this?.$parent?.control?.synopticComponent || null;
      }
    },
    sheet() {
      return this?.control?.sheet || null;
    },
    currentCell: {
      set(value) {
        if (!this.currentCell) return;
        if (value.data_source != this.currentCell.data_source) {
          if (value.data_source == "constant") {
            this.dataId = "";
            this.defaultValue = "";
          } else {
            this.defaultValue = "-";
          }
        }
        this.$set(this.sheet[this.info.row], this.info.column, value);
      },
      get() {
        if (this.sheet && this.info?.row >= 0 && this.info?.column >= 0) {
          return this.sheet[this.info.row][this.info.column];
        }
        return null;
      }
    },
    currentAddress() {
      if (this.currentCell) {
        let char =
          this.info.column <= 90
            ? String.fromCharCode(this.info.column + 65)
            : "!"; // 90=Z and it is a too big table
        return `${char}${this.info.row + 1}`;
      }
      return "";
    },
    xy() {
      if (this.currentCell) {
        return `${this.info.row},${this.info.column}`;
      }
      return "";
    },
    nRows() {
      return this?.sheet?.length || 0;
    },
    nColumns() {
      let max = 0;
      if (this.sheet) {
        this.sheet.forEach((row) => {
          max = row.length > max ? row.length : max;
        });
      }
      return max;
    },
    size() {
      return `${this.nRows}x${this.nColumns}`;
    },
    titleText: {
      set(vlr) {
        if (this?.control?.title) {
          this.$set(this.control.title, "text", vlr);
        }
      },
      get() {
        return this?.control?.title?.text || "";
      }
    },
    titleStyle: {
      set(vlr) {
        if (this?.control?.title) {
          this.$set(this.control.title, "style", vlr);
        }
      },
      get() {
        return this?.control?.title?.style;
      }
    },
    subTitleText: {
      set(vlr) {
        if (this?.control?.subTitle) {
          this.$set(this.control.subTitle, "text", vlr);
        }
      },
      get() {
        return this?.control?.subTitle?.text || "";
      }
    },
    subTitleStyle: {
      set(vlr) {
        if (this?.control?.subTitle) {
          this.$set(this.control.subTitle, "style", vlr);
        }
      },
      get() {
        return this?.control?.subTitle?.style;
      }
    },
    tableClasses() {
      let style = this?.control?.tableStyle || defTableStyle();
      let lst = [];
      for (var name in style.classes) {
        lst.push({
          name: name,
          title: this.$t(name.replace(/table-/i, "")),
          value: style.classes[name]
        });
      }
      return lst;
    },
    tableCellStyle: {
      set(value) {
        if (!this?.control) return;
        let style = this.control?.tableStyle || defTableStyle();
        let lst = (value?.border || "0px solid #666").split(" ");
        if (lst.length > 2) {
          this.$set(style.cssVars, "--border-width", lst.splice(0, 1)[0]);
          this.$set(style.cssVars, "--border-style", lst.splice(0, 1)[0]);
          this.$set(style.cssVars, "--border-color", lst.join(""));
          this.$set(this.control, "tableStyle", style);
        }
      },
      get() {
        let style = this?.control?.tableStyle || defTableStyle();
        let w = style.cssVars["--border-width"] || "0px";
        let s = style.cssVars["--border-style"] || "solid";
        let c = style.cssVars["--border-color"] || "#666";
        return {
          border: `${w} ${s} ${c}`
        };
      }
    },
    errors() {
      let entry = {
        cellStyle: "" // TODO: implement error parser
      };
      return entry;
    },
    extendedDataList() {
      return this.$store.getters["dashboard/extendedDataList"] || [];
    },
    dataSetConfig() {
      if (!this?.control) return null;
      return (
        this?.control?.dataSetConfig || {
          address: "",
          dataList: [],
          missingValues: "interpolation",
          timeColumn: true
        }
      );
    },
    dataSetAddress: {
      set(value) {
        if (!this?.control) return;
        let dataSetConfig = this.dataSetConfig;
        dataSetConfig.address = value || "";
        this.$set(this.control, "dataSetConfig", dataSetConfig);
        this.onDataSetConfigChanged();
      },
      get() {
        return this?.dataSetConfig?.address || "";
      }
    },
    dataSetDataList: {
      set(value) {
        if (!this?.control) return;
        let dataSetConfig = this.dataSetConfig;
        dataSetConfig.dataList = (value || []).map(({ id }) => ({
          data_id: id
        }));
        this.$set(this.control, "dataSetConfig", dataSetConfig);
        this.onDataSetConfigChanged();
      },
      get() {
        return (this?.dataSetConfig?.dataList || []).map((item) => {
          return item
            ? this.getEquipmentData(item.data_id) || {
              id: item.data_id,
              name: "?"
            }
            : null;
        });
      }
    },
    dataSetIdList() {
      return (this.dataSetDataList || []).map(({ id }) => id)
    },
    dataSetTimeColumn: {
      set(value) {
        if (!this?.control) return;
        let dataSetConfig = this.dataSetConfig;
        this.$set(dataSetConfig, "timeColumn", value);
        this.$set(this.control, "dataSetConfig", dataSetConfig);
        this.onDataSetConfigChanged();
      },
      get() {
        return this?.dataSetConfig?.timeColumn || false;
      }
    },
    dataSetSequenceColumn: {
      set(value) {
        if (!this?.control) return;
        let dataSetConfig = this.dataSetConfig;
        this.$set(dataSetConfig, "sequenceColumn", value);
        this.$set(this.control, "dataSetConfig", dataSetConfig);
        this.onDataSetConfigChanged();
      },
      get() {
        return this?.dataSetConfig?.sequenceColumn || false;
      }
    },
    dataSetMissingValues: {
      set(value) {
        if (!this?.control) return;
        let dataSetConfig = this.dataSetConfig;
        this.$set(dataSetConfig, "missingValues", value);
        this.$set(this.control, "dataSetConfig", dataSetConfig);
      },
      get() {
        return this?.dataSetConfig?.missingValues || "last_value";
      }
    },
    isDataSetAddressValid() {
      if (this.sheet) {
        let nCols =
          (this.dataSetDataList || []).length +
          (this.dataSetSequenceColumn ? 1 : 0) +
          (this.dataSetTimeColumn ? 1 : 0);
        let address = this.dataSetAddress || "";
        if (address) {
          let c = parseInt(address.charCodeAt(0) - 65);
          let r = parseInt(address.replace(/\D/g, "")) - 1;
          if (
            r >= 0 &&
            r <= this.sheet.length - 1 &&
            c >= 0 &&
            c + nCols <= this.sheet[0].length
          ) {
            return true;
          }
        }
      }
      return false;
    },
    cellWidth: {
      set(vlr) {
        if (this.currentCell) {
          let value = parseInt(vlr !== "" ? vlr : 0);
          this.currentCell.style.width = value ? value + "%" : "auto";
        }
      },
      get() {
        let vlr = "";
        if (this.currentCell) {
          let perc = this?.currentCell?.style?.width || "auto";
          vlr = perc != "auto" ? perc.replace(/\%/, "") : "";
        }
        return vlr;
      }
    },
    cellExpression: {
      set(value) {
        if (this.currentCell) {
          this.currentCell.value = value;
        }
      },
      get() {
        return this?.currentCell ? this.currentCell.value : "";
      }
    },
    downloadCSV: {
      set(vlr) {
        this.$set(this.control, "downloadCSV", vlr);
      },
      get() {
        return this?.control?.downloadCSV;
      }
    },
    downloadXLS: {
      set(vlr) {
        this.$set(this.control, "downloadXLS", vlr);
      },
      get() {
        return this?.control?.downloadXLS;
      }
    },
    panelTitle() {
      return this?.panel?.title || "";
    },
    controlStyle() {
      return this?.control?.style || null
    },
    dataDisplayLabel() {
      return this.$store.getters["dashboard/dataDisplayLabel"];
    }
  },
  watch: {
    control: {
      handler(n) {
        if (this.panel) {
          // standalone panel
          this.$store.dispatch("dashboard/saveDraftPanel", {
            panel: this.panel,
            screenId: this.draft.screenId
          });
        } else {
          // synoptic control
        }
      },
      deep: true
    },
    panelTitle(n, o) {
      // It also update table title if its current value match the previous panel title;
      // Do not update if user has already forced any change (maz)
      if (n != o) {
        this.titleText = n;
      }
    },
    controlStyle(n, o) {
      if (n && o) {
        for (var p in n) {
          if (n[p] && o[p] && n[p] != o[p]) {
            this.applyStyle(p, n[p])
          }
        }
      }
    }
  },
  methods: {
    A1(r, c) {
      let char = c <= 25 ? String.fromCharCode(c + 65) : "!"; // 90=Z and it is a too big table
      return `${char}${r + 1}`;
    },
    R1C1(address) {
      let r = address && address.length >= 2
        ? [address.charCodeAt(0) - 64, parseInt(address.slice(1))]
        : null;
      return (!r || isNaN(r[0]) || isNaN(r[1])) ? null : { column: r[0], row: r[1] };
    },
    getEquipmentData(id) {
      if (!id) return null;
      return (this.extendedDataList || []).find((i) => i.id == id);
    },
    toggleTableClass(ix) {
      if (!this?.control) return;
      let lst = this.tableClasses;
      let control = this.control || {};
      let tableStyle = control?.tableStyle || defTableStyle();
      this.$set(
        tableStyle.classes,
        lst[ix].name,
        !tableStyle.classes[lst[ix].name]
      );
      this.$set(control, "tableStyle", tableStyle);
    },
    togglePanels(lst) {
      for (var name in this.panels) {
        this.panels[name] = lst.indexOf(name) > -1;
      }
    },
    activateCell($event) {
      return new Promise((resolve) => {
        if (this.cellSearch) {
          this.dataSetAddress = this.A1(
            $event.details.row,
            $event.details.column
          );
          this.cellSearch = false;
          return;
        }
        this.datasetDataPicker = false; // !important - since data picker crashes if more than one instance.
        this.togglePanels(["cell"]);
        this.info = $event.details;
        if (this?.currentCell?.data_id !== "") {
          this.currentCell.data_source = "data";
        }
        this.busy = true;
        this.cellEdit = false;
        this.$nextTick(() => {
          this.busy = false;
          resolve();
        });
      });
    },
    onTableEvent($event) {
      let sheet = this.sheet;
      switch ($event.action) {
        case "sheet:activate": {
          this.info = $event.details;
          if ($event.minHeight !== undefined && !isNaN(parseInt($event.minHeight)) && this.control) {
            this.control.clientRect.height = $event.minHeight;
          }
          if ($event.minWidth !== undefined && !isNaN(parseInt($event.minWidth)) && this.control) {
            this.control.clientRect.width = $event.minWidth;
          }
          this.cellEdit = false;
          this.$nextTick(() => {
            if (!this.currentCell) {
              this.activateCell({ details: { row: 0, column: 0, control: $event?.details?.control || this.control } })
            }
          })
          break;
        }

        case "cell:activate": {
          this.activateCell($event);
          break;
        }

        case "cell:edit": {
          if (!this.currentCell) {
            this.activateCell($event).then(() => {
              this.cellEdit = false;
              this.$nextTick(() => {
                this.cellEdit = true;
              });
            });
            return;
          }
          this.cellEdit = false;
          this.$nextTick(() => {
            this.cellEdit = true;
          });
          break;
        }

        case "cell:set_value": {
          this.togglePanels(["cell"]);
          this.info = $event.details;
          this.cellExpression = $event.details.value;
          break;
        }

        case "cell:set_width": {
          this.togglePanels(["cell"]);
          this.info = $event.details;
          let vlr = $event.details.width;
          if (this.currentCell) {
            let value = parseInt(vlr !== "" ? vlr : 0);
            this.currentCell.style.width = value ? value + "%" : "auto";
          }
          break;
        }

        case "title:activate": {
          this.togglePanels(["header", "title"]);
          this.info = $event.details;
          this.cellEdit = false;
          this.$nextTick(() => {
            if (this.$refs.inpTitle) {
              this.$refs.inpTitle.focus();
            }
          });
          break;
        }

        case "sub_title:activate": {
          this.togglePanels(["header", "subTitle"]);
          this.info = $event.details;
          this.cellEdit = false;
          this.$nextTick(() => {
            if (this.$refs.inpSubTitle) {
              this.$refs.inpSubTitle.focus();
            }
          });
          break;
        }

        case "row:add_above": {
          if (!sheet) return;
          this.info = $event.details;
          let row;
          if (sheet.length) {
            row = JSON.parse(JSON.stringify(sheet[this.info.row]));
          } else {
            row = Array.from({ length: this.nColumns }).map(() => {
              return defCell();
            });
          }
          sheet.splice(this.info.row, 0, row);
          this.$root.$emit("panel:resized");
          this.cellEdit = false;
          break;
        }

        case "row:add_below": {
          if (!sheet) return;
          this.info = $event.details;
          let row;
          if (sheet.length) {
            row = JSON.parse(JSON.stringify(sheet[this.info.row]));
          } else {
            row = Array.from({ length: this.nColumns }).map(() => {
              return defCell();
            });
          }
          sheet.splice(this.info.row + 1, 0, row);
          this.$root.$emit("panel:resized");
          this.cellEdit = false;
          break;
        }

        case "row:del": {
          if (!sheet) return;
          this.info = $event.details;
          sheet.splice(this.info.row, 1);
          if (!this.nRows) {
            let row = Array.from({ length: 1 }).map(() => {
              return defCell();
            });
            sheet.splice(0, 0, row);
          }
          if (this.info.row > this.nRows - 1) {
            this.info.row = this.nRows - 1;
          }
          this.$root.$emit("panel:resized");
          this.cellEdit = false;
          break;
        }

        case "row:reset_style": {
          if (!sheet) return;
          this.info = $event.details;
          sheet[this.info.row].forEach((col) => {
            this.$set(col, "style", defCell().style);
            this.$set(col, "format", "");
          });
          this.cellEdit = false;
          break;
        }

        case "row:reset_content": {
          if (!sheet) return;
          this.info = $event.details;
          sheet[this.info.row].forEach((col) => {
            let standard = defCell();
            for (var p in standard) {
              this.$set(col, p, standard[p]);
            }
          });
          this.cellEdit = false;
          break;
        }

        case "column:add_left": {
          if (!sheet) return;
          this.info = $event.details;
          sheet.forEach((row) => {
            let cell = defCell();
            let ref = JSON.parse(JSON.stringify(row[this.info.column]));
            cell.style = ref.style;
            cell.format = ref.format;
            row.splice(this.info.column, 0, cell);
          });
          this.cellEdit = false;
          if (this.dataSetAddress) {
            this.onDataSetConfigChanged();
          }
          break;
        }

        case "column:add_right": {
          if (!sheet) return;
          this.info = $event.details;
          sheet.forEach((row) => {
            let cell = defCell();
            let ref = JSON.parse(JSON.stringify(row[this.info.column]));
            cell.style = ref.style;
            cell.format = ref.format;
            cell.data_id = ref.data_id;
            cell.data_source = ref.data_source;
            cell.default = ref.default;
            cell.value = ref.value;
            row.splice(this.info.column + 1, 0, cell);
          });
          this.cellEdit = false;
          if (this.dataSetAddress) {
            this.onDataSetConfigChanged();
          }
          break;
        }

        case "column:del": {
          if (!sheet) return;
          this.info = $event.details;
          sheet.forEach((row) => {
            row.splice(this.info.column, 1);
          });
          if (!this.nColumns) {
            sheet.forEach((row) => {
              row.splice(0, 0, defCell());
            });
          }
          if (this.info.column > this.nColumns - 1) {
            this.info.column = this.nColumns - 1;
          }
          this.cellEdit = false;
          if (this.dataSetAddress) {
            this.onDataSetConfigChanged();
          }
          break;
        }

        case "column:reset": {
          if (!sheet) return;
          this.info = $event.details;
          sheet.forEach((row) => {
            this.$set(row[this.info.column], "style", defCell().style);
            this.$set(row[this.info.column], "format", "");
          });
          this.cellEdit = false;
          break;
        }

        case "column:set_width": {
          if (!sheet) return;
          this.info = $event.details;
          let width = parseInt(this.info.width !== "" ? this.info.width : 0);
          let row = this.info.row;
          let column = this.info.column;
          if (
            width &&
            row >= 0 &&
            row < this.sheet.length &&
            column >= 0 &&
            column < this.sheet[row].length
          ) {
            this.togglePanels(["cell"]);
            sheet.forEach((row) => {
              var cell = row[this.info.column];
              cell.style.width = width ? width + "%" : "auto";
            });
          }
          break;
        }

        case "cell:reset": {
          if (!sheet) return;
          this.info = $event.details;
          let cell = sheet[this.info.row][this.info.column];
          this.$set(cell, "style", defCell().style);
          this.$set(cell, "format", "");
          this.cellEdit = false;
          break;
        }
      }
    },
    addDatasetDataId(id) {
      if (id) {
        if (this?.control?.dataSetConfig) {
          let lst = JSON.parse(
            JSON.stringify(this?.control?.dataSetConfig?.dataList || [])
          );
          lst.push({ data_id: id });
          this.control.dataSetConfig.dataList = lst;
        }
        this.datasetDataPicker = false;
        this.onDataSetConfigChanged();
      }
    },
    delDatasetDataId(ix) {
      if (this?.control?.dataSetConfig) {
        let lst = JSON.parse(
          JSON.stringify(this?.control?.dataSetConfig?.dataList || [])
        );
        lst.splice(ix, 1);
        this.control.dataSetConfig.dataList = lst;
        if (!lst.length) {
          this.control.dataSetConfig.address = "";
          this.datasetDataPicker = false;
          this.datasetCollapsed = true;
        }
        this.onDataSetConfigChanged();
      }
    },
    resetDataset() {
      this.control.dataSetConfig.dataList = [];
      this.control.dataSetConfig.address = "";
      this.onDataSetConfigChanged();
    },
    onDataSetConfigChanged() {
      let n = this.dataSetConfig;
      if (n && n.address && n.dataList.length) {
        let rc = this.R1C1(n.address);
        if (rc && this.sheet.length >= rc.row) {
          let row = rc.row - 1;
          // Sequence Column
          var sCol = n.sequenceColumn ? 0 : -1;
          // Time Column
          var tCol = n.timeColumn ? (sCol == 0 ? 1 : 0) : -1;
          // Data inicial column
          var dCol = (sCol >= 0 ? 1 : 0) + (tCol >= 0 ? 1 : 0);
          for (var col = rc.column - 1; col < this.sheet[row].length; col++) {
            if (col == sCol) {
              this.sheet[row][col].data_id = "";
              this.sheet[row][col].data_source = "constant";
              this.sheet[row][col].format = "%d";
            } else if (col == tCol) {
              this.sheet[row][col].data_id = "";
              this.sheet[row][col].data_source = "constant";
              this.sheet[row][col].format = "DD/MM/YYYY HH:mm:ss";
            } else if (col >= dCol) {
              let info = n.dataList[col - dCol] || null;
              if (info) {
                let data = this.dataSetDataList.find(
                  ({ id }) => id == info.data_id
                );
                this.sheet[row][col].data_id = info.data_id;
                this.sheet[row][col].data_source = "data";
                this.sheet[row][col].format =
                  (data && (data.custom_format || defFormat[data.type])) || "";
              } else {
                this.sheet[row][col].data_id = "";
                this.sheet[row][col].data_source = "constant";
                this.sheet[row][col].format = "";
              }
            }
          }
        }
      }
    },
    toggleDatasetDataPicker() {
      if (this.datasetDataPicker) {
        this.datasetDataPicker = false;
      } else {
        this.togglePanels(["table"]);
        this.datasetCollapsed = false;
        this.$nextTick(() => {
          this.datasetDataPicker = true; // wait panel transition before switch it on;
        });
      }
    },
    toggleDatasetCollapsed() {
      if (this.dataSetDataList.length) {
        this.datasetCollapsed = !this.datasetCollapsed;
        this.datasetDataPicker = false;
      } else {
        this.toggleDatasetDataPicker();
      }
    },
    isSimulating(id) {
      let history = this.$store.getters["history/entries"] || {};
      return history && id in history ? true : false;
    },
    toggleSimulation(id) {
      if (this.isSimulating(id)) {
        this.$store.dispatch("history/reset");
      } else {
        this.$store.dispatch("history/simulate", id);
      }
    },
    toggleCellSearch() {
      this.cellSearch = !this.cellSearch;
      if (this.cellSearch) {
        this.dataSetAddress = "";
      }
    },
    toggleCellForm() {
      if (this?.$refs?.cellForm?.toggleFloatPanel) {
        this.$refs.cellForm.toggleFloatPanel();
      }
    },
    async applyStyle(prop, value) {
      for (var r in this.sheet || []) {
        for (var c in this.sheet[r]) {
          if (prop in (this.sheet[r][c].style || {})) {
            this.sheet[r][c].style[prop] = value;
          }
        }
      }
    },
    getItemName(item) {
      if (item?.identity_embedded_app && this.dataDisplayLabel == "identity_embedded_app") {
        return item.identity_embedded_app ?? item.name
      }
      return item.name
    }
  },
  created() {
    this.$root.$on("table:event", this.onTableEvent);
    let panel = this.$store.getters["dashboard/currentDraftPanel"];
    if (panel && panel.template == "DashboardTablePanel") {
      this.onTableEvent({
        action: "sheet:activate",
        details: {
          panelName: panel.name,
          control: panel.options
        }
      });
    }
  },
  beforeDestroy() {
    this.$root.$off("table:event", this.onTableEvent);
  }
};
</script>

<style scoped>
/* label {
  margin-bottom: 0;
} */

.inner-panel {
  padding-left: 4px;
}

.inner-panel > .form-group {
  margin-left: 4px;
  margin-bottom: 6px;
  position: relative;
}

.inner-panel > .form-group > i {
  min-width: 16px;
  margin-right: 4px;
}

.inner-label {
  position: absolute;
  top: -8px;
  left: 5px;
  font-size: 8pt;
  background-color: white;
}

.small-checkbox-label {
  vertical-align: text-top;
  white-space: nowrap;
  font-size: 8pt;
}

.contentPanel {
  margin-top: -10px;
  /* border-top: 1px solid rgb(206, 206, 206); */
}

.dataSource {
  background: whitesmoke;
  padding: 4px 0;
}

.dataSource > label > span {
  vertical-align: top;
  margin: 0 15px 0 2px;
}
.expression-icon {
  color: #666;
  font-size: 80%;
  font-weight: 600;
  letter-spacing: 0px;
  font-family: monospace;
}

.expression-icon.active {
  color: #025dca;
}

.list-group-item {
  padding: 5px;
}
.move-item:hover {
  cursor: move;
  opacity: 0.8;
}

.dataset {
  margin: 0;
  padding: 6px 4px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid transparent;
}

.dataset.active {
  background-color: whitesmoke;
  border-color: lightgray;
}

.dataset-columns {
  padding: 5px 2px;
  margin-top: -15px;
  clear: both;
}
.dataset-columns > .item {
  display: inline-block;
  padding-right: 12px;
  font-size: 95%;
}

.simple-label {
  font-weight: 600;
}

.target-cell {
  padding: 5px 20px;
  background: #e8e8e8;
  border-radius: 5px;
  margin: -15px 2px 0 2px;
}
.dataset-item-name {
  display: inline-block;
  max-width: calc(100% - 60px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: text-bottom;
}

.cell-edit-button {
  color: royalblue;
  /* background-color: whitesmoke; */
  padding: 4px 10px 6px 10px;
}

.cell-edit-button > i {
  color: transparent;
  font-size: 90%;
  vertical-align: top;
}

.cell-edit-button:hover {
  color: #5e82a2;
  background-color: rgb(219, 219, 219);
  border-color: rgb(209, 209, 209);
}

.cell-edit-button:hover > i {
  color: #226288;
}
</style>
