<template>
  <div
    v-if="modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    ref="modalDialog"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title">{{ $tc(title, 2) }}</h4>
        </div>
        <div
          class="modal-body"
          style="padding: 0; min-height: 60vh; overflow: auto; text-align: left"
        >
          <FileExplorerContainer
            style="height: 100%; min-height: 200px; resize: none"
            v-if="sidebarEnabled && tree.show"
            @sidebarResize="$emit('sidebarResize')"
          >
            <template #sidebar>
              <FileExplorerSideBar
                class="fade-in"
                :class="ready ? 'easy-show' : 'easy-hide'"
                :title="$tc('item', 2)"
                :toolbar="toolbar"
                @command="onCommand"
              >
                <template #content>
                  <slot name="sidebarContent">
                    <div ref="folders"></div>
                  </slot>
                </template>
              </FileExplorerSideBar>
            </template>
            <template #main>
              <slot name="files"></slot>
            </template>
          </FileExplorerContainer>
          <template v-else>
            <slot name="files"></slot>
          </template>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="search-bar">
      <slot name="search"></slot>
    </div>
    <template v-if="sidebarEnabled && tree.show">
      <FileExplorerContainer
        style="resize: unset"
        :sidebarLarge="toolbar.length > 2"
        @sidebarResize="$emit('sidebarResize')"
      >
        <template #sidebar>
          <div v-if="!ready" class="alert alert-default">
            <p class="text-center">
              <i class="fa fa-refresh fa-spin"></i> {{ $t("loading") }}
            </p>
          </div>
          <FileExplorerSideBar
            class="fade-in"
            :class="[
              ready ? 'easy-show' : 'easy-hide',
              isOutOfDate ? 'out-of-date' : ''
            ]"
            :title="$tc(title)"
            :toolbar="toolbar"
            @command="onCommand"
          >
            <template #content>
              <slot name="sidebarContent"></slot>
              <div>
                <div ref="folders"></div>
              </div>
            </template>
          </FileExplorerSideBar>
        </template>
        <template #main>
          <slot name="files"></slot>
        </template>
      </FileExplorerContainer>
    </template>
    <template v-else>
      <slot name="files"></slot>
    </template>
  </div>
</template>

<script>
import { debounce, isEqual, uniq, uniqBy, pick, } from "lodash";
import { dftToolbar } from "@/components/editor/file-explorer-sidebar.vue";
import { treeStorage } from "@/services/dashboard.js";
import FileExplorerContainer from "@/components/editor/file-explorer-container.vue";
import FileExplorerSideBar from "@/components/editor/file-explorer-sidebar.vue";

const dftIconSet = "images";
const eq = (a, b) => {
  return isEqual(JSON.parse(JSON.stringify(a)), JSON.parse(JSON.stringify(b)));
};

const initialUserProperties = (dbKey) => {
  if (!dbKey) return {};
  const tree = treeStorage(dbKey);
  return ((tree && tree.userProperties) ?? {}) || {};
};

const Icons = (key) => {
  const iconSet = {
    "fa-light": {
      opened_folder: "fa fa-folder-open-o",
      closed_folder: "fa fa-folder-o",
      leaf: "fa fa-file-o"
    },
    "fa-dark": {
      opened_folder: "fa fa-folder-open",
      closed_folder: "fa fa-folder",
      leaf: "fa fa-file-o"
    },
    glyphicon: {
      opened_folder: "glyphicon glyphicon-folder-open",
      closed_folder: "glyphicon glyphicon-folder-close",
      leaf: "glyphicon glyphicon-file"
    },
    images: {
      opened_folder: "/static/common/images/folder-opened.png",
      closed_folder: "/static/common/images/folder-closed.png",
      leaf: "glyphicon glyphicon-file"
    },
    default: {
      opened_folder: true,
      closed_folder: true,
      leaf: "fa fa-file-o"
    }
  };
  return iconSet[key] ?? iconSet[dftIconSet];
};

const trashCan = (key) => {
  const options = {
    "fa-light": {
      id: "trash_can",
      text: "trash_can",
      icon: "fa fa-trash-o",
      state: {
        opened: false,
        hidden: true,
        selected: false
      },
      children: []
    },
    "fa-dark": {
      id: "trash_can",
      text: "trash_can",
      icon: "fa fa-trash",
      state: {
        opened: false,
        hidden: true,
        selected: false
      },
      children: []
    },
    glyphicon: {
      id: "trash_can",
      text: "trash_can",
      icon: "glyphicon glyphicon-trash",
      state: {
        opened: false,
        hidden: true,
        selected: false
      },
      children: []
    },
    images: {
      id: "trash_can",
      text: "trash_can",
      icon: "fa fa-trash-o",
      state: {
        opened: false,
        hidden: true,
        selected: false
      },
      children: []
    },
    default: {
      id: "trash_can",
      text: "trash_can",
      icon: "fa fa-trash-o",
      state: {
        opened: false,
        hidden: true,
        selected: false
      },
      children: []
    }
  };
  return (key && options[key]) ?? options[dftIconSet];
};

const initialData = () => ({
  tree: {
    show: false,
    selectedNode: "root",
    selectedIconSet: dftIconSet,
    folders: [
      {
        id: "root",
        text: "/",
        icon: Icons(dftIconSet).opened_folder,
        state: {
          opened: true,
          selected: true
        },
        children: []
      }
    ],
    leaves: {},
    _v: 0,
    customIcons: null,
    userProperties: {}
  },
  ready: false,
  publishing: false,
  iUserProperties: null,
  s: 0,
  isSynchronized: true,
  sort: {
    enabled: false,
    direction: -1
  },
  isOutOfDate: false // changes made in another tab
});

export { initialUserProperties };
export default {
  name: "FileExplorer",
  props: {
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    modal: {
      type: Boolean,
      required: false,
      default: false
    },
    multiSelection: {
      type: Object,
      required: false,
      default: () => ({ key: null, values: [] })
    },
    title: {
      type: String,
      required: false,
      default: "untitled"
    },
    maxResult: {
      type: Number,
      default: 0,
      required: 0
    },
    dbKey: {
      type: String,
      default: "files",
      required: false
    },
    customToolbar: {
      type: Array,
      required: false,
      default: () => undefined
    },
    publishEnabled: {
      type: Boolean,
      required: false,
      default: true
    },
    sidebarEnabled: {
      type: Boolean,
      required: false,
      default: true
    },
    discarded: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  components: {
    FileExplorerContainer,
    FileExplorerSideBar
  },
  data: initialData,
  computed: {
    entries() {
      return uniqBy(this.items || [], "id");
    },
    canPublish() {
      return !this.isSynchronized; //todo: check contract permission policies      
    },
    payload() {
      return this.RTPayload();
    },
    standardToolbar() {
      let items = [...dftToolbar()];
      let item = items.find(({ id }) => id == "publish");
      item.pending = this.publishing;
      item.disabled = !this.canPublish;
      return items;
    },
    toolbar() {
      return this.customToolbar || this.standardToolbar || [];
    },
    userProperties: {
      set(value) {
        // this.$set(this, "iUserProperties", JSON.parse(JSON.stringify(value || {})));
        this.$set(this, "iUserProperties", value || {});
        if (this.tree) {
          (async (v) => {
            this.tree.userProperties = JSON.parse(JSON.stringify(v));
            this.saveLocal(undefined, () => 0);
          })(value);
        }
      },
      get() {
        return this.iUserProperties || {};
      }
    },
    contractTree() {
      let portal_data = this.contractPortalData();
      return portal_data && portal_data[this.dbKey]
        ? portal_data[this.dbKey]
        : null;
    }
  },
  watch: {
    entries: {
      handler(n, o) {
        if (n && (!o || !o.length)) {
          this.restore();
          if (this.tree.show) {
            this.$nextTick(() => {
              this.buildUI(() => {
                if (this.tree.selectedNode == 'trash_can') {
                  this.selectRoot();
                }
              });
              if (this.validateContractVersion()) {
                this.isOutOfDate = true;
              }
              else if (
                n.length &&
                Object.keys(this?.contractTree?.leaves || {}).length &&
                n.length != Object.keys(this?.contractTree?.leaves || {}).length
              ) {
                this.isSynchronized = false;
              }
            });
          } else {
            this.ready = true;
          }
        }
        // else if (n && o) {
        //   this.updateTrashCan();
        // }
      },
      immediate: true
    },
    payload(n) {
      if (n) this.$emit("change", n);
    },
    discarded: {
      handler() {
        this.updateTrashCan();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onCommand(command) {
      switch (command) {
        case "close":
          this.toggle();
          break;

        case "publish":
          this.saveRemote();
          break;

        case "undo":
          this.undo();
          break;

        default: {
          const _run = (extSideBar) => {
            this.$emit("command", command);
            this.$nextTick(() => {
              if (extSideBar && !this.$scopedSlots.sidebarContent) {
                this.restore();
                this.buildUI();
              } else if (!extSideBar && this.$scopedSlots.sidebarContent) {
                if (this._$jst) {
                  this._$jst.jstree("destroy", true);
                  this._$jst = null;
                }
              }
            });
          };
          const extSideBar = this.$scopedSlots.sidebarContent ? true : false;
          extSideBar
            ? _run(extSideBar)
            : this.saveLocal(true, () => {
              _run(extSideBar);
            });
          break;
        }
      }
    },
    close() {
      $(this.$refs.modalDialog).modal("hide");
    },
    open() {
      this.command = null;
      $(this.$refs.modalDialog).modal("show");
    },
    dragStart($e) {
      const item = {
        id: $e?.currentTarget?.dataset?.itemId || "",
        name: $e.currentTarget.dataset.itemName || ""
      };
      if (!item.id) return;
      let lst = (this.multiSelection.values || [])
        .filter(
          (id) =>
            parseInt(id) !== parseInt(item.id) &&
            this.tree.leaves[item.id] === this.tree.leaves[id]
        )
        .concat([parseInt(item.id)]);
      let plural = lst.length > 1 ? `<sup> +${lst.length}</sup>` : "";
      $.vakata.dnd.start(
        $e,
        {
          jstree: true,
          obj: { id: item.id },
          nodes: [
            {
              id: true,
              text: `${item.name}`,
              idList: lst
            }
          ]
        },
        `<div id="jstree-dnd" class="jstree-default" style='border:1px solid #cacaca;background-color:whitesmoke;opacity:.7;padding:2px 3px;border-radius:3px;'><i class="jstree-icon jstree-er"></i>${item.name}${plural}</div>`
      );

    },
    undo() {
      this.$utils
        .confirm(this, "you_wont_be_able_to_revert_this", "are_you_sure")
        .then((r) => {
          if (!r) return;
          this.ready = false;
          // parent would reset its search here
          this.$emit("beforeRestore");
          this.$nextTick(() => {
            this.$store.dispatch("user/configureUserContract", false).then((contract) => {
              if (!contract) {
                this.ready = true;
                this.toast(this.$t("unknown_login_error"));
                return;
              }
              treeStorage(this.dbKey, null);
              this.isOutOfDate = false;
              // Important: give some time, so parent can reset to all items before build it up again.
              setTimeout(() => {
                this.restore(true);
                this.cleanUp();
                this.$nextTick(() => {
                  this.buildUI(() => {
                    this.updateTrashCan();
                    this._$jst.jstree("deselect_all");
                    this._$jst.jstree("select_node", "root");
                    this.saveLocal();
                  });
                });
              }, 200);
            })
          })
        });
    },
    restore(undo) {
      let dftTree = initialData().tree;
      let tree = dftTree;
      let entry, rEntry, lEntry = null;
      try {
        rEntry = this.contractTree;
        lEntry = window.localStorage.getItem(this.dbKey) ?? null;
        entry = lEntry ? JSON.parse(lEntry) : null;
        var isValid = entry && (entry?.folders ?? []).some(({ id }) => id == 'root');
        if ((undo || !isValid) && rEntry) {
          entry = JSON.parse(JSON.stringify(rEntry));
        }
      } catch (error) {
        entry = null;
      }
      tree = entry ? { ...tree, ...(entry || {}) } : tree;
      if (lEntry && tree.userProperties && this.iUserProperties === null) {
        // Important:
        // Note: User properties are only valid localy, therefore global contract definition should not overwrite it
        // The userProperties initialization function does not take in account global contract definitions.
        // 
        this.iUserProperties = JSON.parse(JSON.stringify(tree.userProperties));
      }
      this.$set(this, "tree", tree);
      if (!this.$scopedSlots.sidebarContent) {
        let invalid = { ...tree.leaves };
        (this?.entries || []).forEach(({ id }) => {
          this.$set(this.tree.leaves, id, (this.discarded || []).some((i) => parseInt(i) == parseInt(id)) ? "trash_can" : (tree.leaves[id] || "root"));
          delete invalid[id];
        });
        this.configureTrashCan();
        Object.keys(invalid || {}).forEach((id) => {
          this.$delete(this.tree.leaves, id);
        });
      }
    },
    configureTrashCan() {
      // todo: if it has been moved, remove from any children
      this.tree.folders = this.tree.folders.filter(({ id }) => id != 'trash_can');
      var trash_can = trashCan(this.tree.selectedIconSet);
      trash_can.text = this.$t(`titles.${trash_can.text}`)
      trash_can.state.hidden = !(this.discarded || []).length;
      trash_can.state.selected = this.tree.selectedNode == 'trash_can';
      this.tree.folders.push(trash_can);
    },
    updateTrashCan() {
      this._updateTrashCan = this._updateTrashCan || debounce(() => {
        (this.discarded || []).forEach((id) => {
          this.$set(this.tree.leaves, id, 'trash_can');
        });
        let $t, node;
        $t = (this._$jst && this._$jst.jstree()) || null;
        if (!$t) return;
        node = $t.get_node("trash_can");
        if (node) {
          node.state.hidden = !(this.discarded || []).length;
          $t.redraw_node(node, true);
          if (!(this.discarded || []).length && this.tree.selectedNode == 'trash_can') {
            this.selectRoot();
          }
        }
      }, 500);
      this._updateTrashCan();
    },
    saveDraft(visible) {
      let payload = this.RTPayload();
      if (visible !== undefined) payload.show = visible;
      treeStorage(this.dbKey, payload);
      return payload;
    },
    saveLocal(visible, callback) {
      this._saveLocal = this._saveLocal || (() => this.validateSync(this.saveDraft(visible)));
      if (callback && typeof callback == "function") {
        this._saveLocal();
        callback();
      }
      else {
        this._delayedSaveDraft = this._delayedSaveDraft || debounce(this._saveLocal, 200);
        this._delayedSaveDraft();
      }
    },
    RTPayload() {
      if (!this.ready) return undefined;
      let payload = JSON.parse(JSON.stringify(this.tree));
      if (this._$jst) {
        payload.folders = this._simplify(this._$jst.jstree().get_json());
      }
      return payload;
    },
    contractPortalData() {
      let contract =
        (this.$store.getters["user/loggedUser"] || {})?.contract || null;
      return !contract || !contract.portal_data ? null : contract.portal_data;
    },
    saveRemote() {
      if (this.isSynchronized || !this.contractPortalData()) return;
      let portal_data = { ...this.contractPortalData() };
      let payload = this.RTPayload();
      payload.show = true;
      payload.selectedNode = "root";
      payload._v = parseInt(payload._v ?? 0) + 1;
      portal_data[this.dbKey] = payload;
      this.publishing = true;
      this.$store
        .dispatch("user/updateContractPortalData", portal_data)
        .then(() => {
          this.tree._v = payload._v;
          this.isOutOfDate = false;
          this.isSynchronized = true;
          this.saveDraft();
          this.toast(this.$t("you_have_saved_n_items", { count: 1 }));
          this.publishing = false;
        }).catch((e) => {
          this.publishing = false;
          this.toast(`${this.$t('item_could_not_be_saved')}<br/>${e?.message || ''}`, 'error')
        });
    },
    buildUI(cb) {
      let self = this;
      if (this.$scopedSlots.sidebarContent) {
        this.ready = true;
        if (cb && typeof cb == "function") {
          cb();
        }
        return;
      }
      if (this._$jst) {
        this._$jst.jstree("destroy", true);
        this._$jst = null;
      }

      if (!this.$refs.folders) {
        this.ready = true;
        if (cb && typeof cb == "function") {
          cb();
        }
        return;
      }

      const customContentMenuItems = (node) => {
        if (node.id == 'trash_can') {
          return null;
        }
        let items = {
          createItem: {
            label: self.$t("create"),
            action: $.jstree.defaults.contextmenu.items().create.action,
            icon: "fa fa-folder-o"
          },
          renameItem: {
            label: self.$t("rename"),
            action: $.jstree.defaults.contextmenu.items().rename.action,
            icon: "fa fa-edit"
          },
          deleteItem: {
            label: self.$t("delete"),
            action: $.jstree.defaults.contextmenu.items().remove.action,
            icon: "fa fa-trash"
          },
          editItem: {
            separator_before: true,
            label: self.$t("style"),
            icon: "fa fa-magic",
            submenu: {
              "fa-light": {
                key: "fa-light",
                label: "fa-light",
                icon: "fa fa-folder-open-o",
                action: (e) => {
                  // var node = $.jstree
                  //   .reference(e.reference)
                  //   .get_node(e.reference);
                  // console.log(node);
                  self.changeIconSet(e.item.key);
                }
              },
              "fa-dark": {
                key: "fa-dark",
                label: "fa-dark",
                icon: "fa fa-folder-open",
                action: (e) => {
                  self.changeIconSet(e.item.key);
                }
              },
              glyphicon: {
                key: "glyphicon",
                label: "glyphicon",
                icon: "glyphicon glyphicon-folder-open",
                action: (e) => {
                  self.changeIconSet(e.item.key);
                }
              },
              images: {
                key: "images",
                label: "images",
                icon: "/static/common/images/folder-opened.png",
                action: (e) => {
                  self.changeIconSet(e.item.key);
                }
              },
              jstree: {
                key: "default",
                label: "default",
                icon: "jstree-default-folder-icon",
                action: (e) => {
                  self.changeIconSet(e.item.key);
                }
              }
            }
          },
          sortItem: {
            separator_before: true,
            label: self.$t("sort"),
            icon: "fa fa-sort",
            submenu: {
              "fa-sort-alpha-asc": {
                key: "fa-sort-alpha-asc",
                label: self.$t("ascendent"),
                icon: "fa fa-sort-alpha-asc",
                action: () => {
                  self.sort.direction = 1;
                  self.sortNodes();
                }
              },
              "fa-sort-alpha-desc": {
                key: "fa-sort-alpha-desc",
                label: self.$t("descendent"),
                icon: "fa fa-sort-alpha-desc",
                action: () => {
                  self.sort.direction = -1;
                  self.sortNodes();
                }
              }
            }
          }
        };
        if (node.id == 'root') {
          delete items.deleteItem;
        }
        return items;
      };

      this._$jst = $(this.$refs.folders)
        .on("ready.jstree", (e, data) => {
          $(document).on("dnd_stop.vakata", (e, data) => {
            self.$emit("drop");
            self.saveLocal();
            setTimeout(() => {
              this.isSynchronized = false;
            }, 1000, self)
          });
          $(document).on("dnd_move.vakata", (e, data) => {
            var t = $(data.event.target);
            if (!t.closest(".jstree").length) {
              if (t.closest(".drop").length) {
                data.helper
                  .find(".jstree-icon")
                  .removeClass("jstree-er")
                  .addClass("jstree-ok");
              } else {
                data.helper
                  .find(".jstree-icon")
                  .removeClass("jstree-ok")
                  .addClass("jstree-er");
              }
            }
          });
          this.ready = true;
          if (cb && typeof cb == "function") {
            cb();
          }
        })
        .on("destroy.jstree", (e, data) => {
          if (
            self.$scopedSlots.sidebarContent &&
            e &&
            e.target &&
            e.target.children.length
          ) {
            e.target.children[0].remove();
          }
        })
        .on("open_node.jstree", (e, data) => {
          data.instance.set_icon(
            data.node,
            Icons(self.tree.selectedIconSet).opened_folder
          );
          self.saveLocal();
        })
        .on("close_node.jstree", (e, data) => {
          data.instance.set_icon(
            data.node,
            Icons(self.tree.selectedIconSet).closed_folder
          );
          self.saveLocal();
        })
        .on("delete_node.jstree", (e, data) => {
          (self?.entries || []).forEach(({ id }) => {
            if (
              self.tree.leaves[id] == data.node.id ||
              (data.node?.children_d || []).indexOf(self.tree.leaves[id]) >= 0
            ) {
              self.tree.leaves[id] = "root";
            }
          });
          self._$jst.jstree("select_node", data.node.parent);
          this.tree.selectedNode = data.node.parent;
          self.saveLocal();
        })
        .jstree({
          plugins: [
            "contextmenu",
            "changed",
            "conditionalselect",
            "dnd",
            "html_data",
            "types",
            "sort"
          ],
          contextmenu: {
            select_node: true,
            show_at_node: true,
            items: customContentMenuItems
          },
          dnd: {
            is_draggable: function(nodes) {
              return !(nodes || []).some(({ id }) => (id == "trash_can" || id == "root"))
            }
          },
          conditionalselect: (node, event) => {
            let p = self.tree.selectedNode;
            self.tree.selectedNode = node.id;
            if (p != self.tree.selectedNode) {
              self.saveLocal();
            }
            return true;
          },
          core: {
            check_callback: function(
              operation,
              node,
              node_parent,
              node_position
            ) {
              if (operation === "create_node") {
                node.id = self.$utils.uuid();
                node.icon = Icons(self.tree.selectedIconSet).closed_folder;
                self.saveLocal();
              } else if (operation == "delete_node" && node.id == "root") {
                self.saveLocal();
                return false;
              } else if (
                operation == "rename_node" ||
                operation == "move_node"
              ) {
                if (operation == "rename_node") {
                  self.saveLocal();
                }
              } else if (operation == "copy_node" && node.idList) {
                var event = { from: null, to: node_parent.id, items: [] };
                (node?.idList || []).forEach((id) => {
                  event.from = self.tree.leaves[id];
                  event.items.push(id);
                  self.tree.leaves[id] = node_parent.id;
                });
                self.saveLocal();
                if (event.from !== event.to && event.items.length) {
                  self.$emit("move", event);
                }
                return false;
              }
              return true;
            },
            multiple: false,
            animation: 0,
            data: this.tree.folders
          },
          sort: function(a, b) {
            if (!self.sort.enabled) return 0;
            var ta = this.get_node(a)?.text || "";
            var tb = this.get_node(b)?.text || "";
            return ta > tb
              ? self.sort.direction
              : tb > ta
                ? -1 * self.sort.direction
                : 0;
          }
        });
    },
    toggle() {
      !this.tree.show ? this.show() : this.hide();
    },
    show() {
      this.restore();
      this.tree.show = true;
      this.$nextTick(() => {
        this.buildUI(() => {
          this.saveLocal(true, () => { });
        });
      });
    },
    hide() {
      this.saveLocal(false, () => {
        this.tree.show = false;
      });
    },
    leafUpdated(entry) {
      // console.log("leafUpdated");
      // console.log(entry);
      if (
        !this?.tree?.leaves ||
        !entry ||
        entry?.from === undefined ||
        entry?.to === undefined ||
        !this.tree.leaves[entry.from]
      )
        return;
      this.$set(this.tree.leaves, entry.to, this.tree.leaves[entry.from]);
      this.$delete(this.tree.leaves, entry.from);
      this.saveLocal(this.tree.show, () => { });
    },
    moveLeaves(lst, nodeId) {
      if (!this?.tree?.leaves || !lst.length) return;
      let save = true;
      (lst || []).forEach((id) => {
        if (this.tree.leaves[id]) {
          this.$set(this.tree.leaves, id, nodeId || "root");
          save = true;
        }
      });
      if (save) this.saveLocal(this.tree.show, () => { });
    },
    changeIconSet(key) {
      let $el = this._$jst.jstree();
      let icons = Icons(key);
      let entry = $el.get_json();
      const applyNode = (node) => {
        $el.set_icon(
          node.id,
          icons[node.state.opened ? "opened_folder" : "closed_folder"]
        );
        if (node && node?.children?.length) {
          for (let i = 0; i < node.children.length; i++) {
            applyNode(node.children[i]);
          }
        }
      };
      this.tree.selectedIconSet = key;
      applyNode(entry[0]);
      this.saveLocal();
    },
    findNodesByLeafId(leaves) {
      let $t = (this._$jst && this._$jst.jstree()) || null;
      if (!$t) return;
      let curNodeId = $t.get_selected().length ? $t.get_selected()[0] : undefined;
      let selNodeId = null;
      let nId = null, node, parentNode = null, $el = null, eId = null;
      for (eId in (this._found || {})) {
        $el = document.getElementById(eId) || null;
        if ($el) {
          $el.classList.remove('in-search');
        }
      };
      this._found = {};
      (leaves || []).forEach((id) => {
        nId = this.tree.leaves[id];
        node = nId ? $t.get_node(nId) : null;
        if (!node) return;
        eId = `${nId}_anchor`;
        if (!this._found[eId]) {
          if (!selNodeId || (curNodeId && curNodeId == nId)) selNodeId = nId;
          if (!node?.state?.opened) {
            $t.open_node(node);
          }
          (node.parents || []).forEach((id) => {
            if (id === "#") return;
            parentNode = $t.get_node(id);
            if (parentNode && !parentNode?.state?.opened) {
              $t.open_node(parentNode);
            }
          });
          $el = document.getElementById(eId) || null;
          if ($el) {
            this._found[eId] = true;
            $el.classList.add('in-search');
          }
        }
      });
      if (selNodeId && selNodeId !== curNodeId) {
        this._$jst.jstree('activate_node', selNodeId);
      }
    },
    toast(msg, type) {
      this.$toasted.show(msg, {
        singleton: true,
        type: type == 'error' ? "error" : "success",
        icon: type == 'error' ? "fa-exclamation-triangle" : "fa-check",
        iconPack: "fontawesome",
        position: "bottom-right",
        duration: 5000,
        action: {
          icon: "fa-close",
          onClick: (e, me) => {
            me.goAway(0);
          }
        }
      });
    },
    signature(root, prop) {
      let lst = [];
      const run = (node) => {
        lst.push(node[prop])
        for (var i in (node?.children || [])) {
          run(node.children[i]);
        }
      };
      run(root);
      return lst.sort().join();
    },
    async validateSync(payload) {
      if (!payload) {
        this.isSynchronized = true;
        return
      }
      if (!this.contractTree) {
        this.isSynchronized = false;
        return
      }
      var l /*local*/, r /*remote*/, lp /* specific prop */, rp/* specific prop */;
      // leaves
      l = pick(payload, ['leaves'])?.leaves ?? {};
      r = pick(this.contractTree, ['leaves'])?.leaves ?? {};
      if (!eq(l, r)) {
        this.isSynchronized = false;
        return
      }
      // folders
      l = pick(payload, ['folders'])?.folders ?? [];
      r = pick(this.contractTree, ['folders'])?.folders ?? [];
      // folder id
      lp = l.length && this.signature(l[0], 'id') || ""
      rp = r.length && this.signature(r[0], 'id') || ""
      if (!eq(lp, rp)) {
        this.isSynchronized = false;
        return;
      }
      // folder name
      lp = l.length && this.signature(l[0], 'text') || ""
      rp = r.length && this.signature(r[0], 'text') || ""
      if (!eq(lp, rp)) {
        this.isSynchronized = false;
        return;
      }
      this.isSynchronized = true;
    },
    sortNodes() {
      let $t, nId, node;
      $t = (this._$jst && this._$jst.jstree()) || null;
      if (!$t) return;
      nId = $t.get_selected().length ? $t.get_selected()[0] : undefined;
      if (!nId) return;
      node = nId ? $t.get_node(nId) : null;
      if (!node) return;
      this.sort.enabled = true;
      $t.sort(node);
      $t.redraw_node(node, true);
      this.sort.enabled = false;
      this.saveLocal(true, () => {
        this.isSynchronized = false;
      });
    },
    cleanUp() {
      // any saved leaf assigned to a folder that does not exist anymore will be moved to the root folder
      if (!this?.tree?.folders?.length || !this?.entries?.length) return;
      let nodeIdList = uniq(Object.values(this.tree.leaves));
      const _getNode = (id, node) => {
        if (node.id == id) return node;
        else if (node.children && node.children.length) {
          for (var i = 0; i < node.children.length; i++) {
            var n = _getNode(id, node.children[i]);
            if (n) return n;
          }
        }
        return null;
      };
      nodeIdList.forEach((id) => {
        if (!_getNode(id, this.tree.folders[0])) {
          for (var leafId in this.tree.leaves) {
            if (this.tree.leaves[leafId] == id) {
              this.tree.leaves[leafId] = 'root';
              console.log(`${leafId} moved to root`);
            }
          }
        }
      });
    },
    validateContractVersion() {
      return ((this.tree && this.contractTree && this.contractTree._v && this.tree._v && this.contractTree._v > this.tree._v) || false)
    },
    selectRoot() {
      this.tree.selectedNode = 'root';
      this._$jst && this._$jst.jstree('activate_node', "root");
      this.saveLocal();
    }
  },
  mounted() {
    if (this.modal) {
      let self = this;
      $(this.$refs.modalDialog)
        .on("shown.bs.modal", () => {
          self.$root.$emit("editor.keyboard:stop");
          self.$emit("open");
        })
        .on("hidden.bs.modal", () => {
          self.$root.$emit("editor.keyboard:start");
          self.$emit("close");
        });
      this.open();
    }
  },
  created() {
    this.$root.$on("entity:id-changed", this.leafUpdated);
    window.onstorage = () => {
      let tree = treeStorage(this.dbKey);
      if (tree && tree._v && this.tree && (this.tree._v < tree._v || this.validateContractVersion())) {
        // changes might be made in another tab (local version is therefore smaller) or on contract (page was reloaded with saved draft)
        this.isOutOfDate = true;
      }
    };
  },
  beforeCreate() {
    this.$root.$off("entity:id-changed", this.leafUpdated);
    this._simplify = (ns) =>
      ns.map((n) => {
        if (n.children && n.children?.length) {
          n.children = this._simplify(n.children);
        }
        return {
          id: n.id,
          text: n.text,
          icon: n.icon,
          state: {
            opened: n.state.opened,
            selected: n.state.selected
          },
          children: n.children
        };
      });
  },
  beforeDestroy() {
    if (this._$jst) {
      this._$jst.jstree("destroy", true);
      this._$jst = null;
    }
  }
};
</script>

<style scoped>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.search-bar {
  padding: 0 0 30px 0;
}

.table-container {
  min-height: 100px;
  max-height: 100%;
}

.modal-body {
  max-height: 600px;
  overflow: auto;
}

.modal-body > .table-container {
  padding: 0 15px;
}

.sep {
  color: #bbbbbb;
}

.modal-lg {
  min-width: 70vw;
}

.modal-lg > .modal-content {
  border-radius: 4px;
}

.easy-hide {
  opacity: 0;
}
.easy-show {
  opacity: 1;
}
.fade-in {
  transition: opacity 0.1s ease-out;
}
</style>

<style>
.out-of-date i.fa-history {
  color: #a94442;
}

a.jstree-anchor.jstree-clicked {
  background: #dedfdf;
  border-radius: 5px;
  color: black;
  text-shadow: 1px 0px gray;
}
a.jstree-anchor.in-search {
  color: #ff0000;
  text-shadow: 0px 0px 2px #9c9c9c;
  font-weight: 600;
}
.vakata-context li > a > i.jstree-default-folder-icon {
  background-repeat: no-repeat;
  background-position: -260px -4px;
  background-image: url(/static/common/lib/images/jstree-32px.png);
  background-repeat: no-repeat;
  margin: 3px -3px 0 -25px;
}
</style>
