<template>
  <div style="position: relative">
    <editor-menu-bar
      class="editor-menu"
      :editor="editor"
      v-slot="{ commands, isActive, getMarkAttrs }"
    >
      <div class="btn-toolbar" role="toolbar" data-testid="menu-bar">
        <div class="btn-group btn-group-justified" role="group">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-default dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span class="fa fa-font"></span><span class="caret"></span>
            </button>
            <ul class="dropdown-menu dropdown-inline">
              <!-- Bold -->
              <li>
                <a
                  href="javascript:void(0)"
                  :class="{ 'is-active': isActive.bold() }"
                  @click.prevent="commands.bold"
                  :title="$t('rich_text.bold')"
                  data-testid="bold"
                >
                  <span class="fa fa-bold"></span>
                </a>
              </li>
              <!-- Italic -->
              <li>
                <a
                  href="javascript:void(0)"
                  :class="{ 'is-active': isActive.italic() }"
                  @click.prevent="commands.italic"
                  :title="$t('rich_text.italic')"
                  data-testid="italic"
                >
                  <span class="fa fa-italic"></span>
                </a>
              </li>
              <!-- Underline -->
              <li>
                <a
                  href="javascript:void(0)"
                  :class="{ 'is-active': isActive.underline() }"
                  @click.prevent="commands.underline"
                  :title="$t('rich_text.underline')"
                  data-testid="underline"
                >
                  <span class="fa fa-underline"></span>
                </a>
              </li>
              <!-- Strikethrough -->
              <li>
                <a
                  href="javascript:void(0)"
                  :class="{ 'is-active': isActive.strike() }"
                  @click.prevent="commands.strike"
                  :title="$t('rich_text.strikethrough')"
                  data-testid="strikethrough"
                >
                  <span class="fa fa-strikethrough"></span>
                </a>
              </li>
            </ul>
          </div>
          <ColorPicker
            class="btn-group"
            id="color"
            icon="glyphicon glyphicon-text-color"
            buttonClass="btn-default"
            :updateOnCloseOnly="true"
            :value="
              (getMarkAttrs('color') && getMarkAttrs('color').value) ||
              'transparent'
            "
            @input="commands.color({ value: $event })"
            :title="$tc('color')"
          />
          <!-- Heading -->
          <div class="btn-group dropdown" role="group">
            <button
              :class="[
                'btn dropdown-toggle',
                isActive.heading() ? 'btn-primary' : 'btn-default'
              ]"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              :title="$tc('rich_text.heading')"
              data-testid="heading"
            >
              <span class="fa fa-header"></span> <span class="caret"></span>
            </button>
            <ul class="dropdown-menu">
              <li>
                <a
                  :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                  href="javascript:void(0)"
                  @click.prevent="commands.heading({ level: 1 })"
                  :title="$tc('rich_text.heading', 2, { level: 1 })"
                  data-testid="heading-1"
                  >{{ $t("h1") }}</a
                >
              </li>
              <li>
                <a
                  :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                  href="javascript:void(0)"
                  @click.prevent="commands.heading({ level: 2 })"
                  :title="$tc('rich_text.heading', 2, { level: 2 })"
                  data-testid="heading-2"
                  >{{ $t("h2") }}</a
                >
              </li>
              <li>
                <a
                  :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                  href="javascript:void(0)"
                  @click.prevent="commands.heading({ level: 3 })"
                  :title="$tc('rich_text.heading', 2, { level: 3 })"
                  data-testid="heading-3"
                  >{{ $t("h3") }}</a
                >
              </li>
              <li>
                <a
                  :class="{ 'is-active': isActive.heading({ level: 4 }) }"
                  href="javascript:void(0)"
                  @click.prevent="commands.heading({ level: 4 })"
                  :title="$tc('rich_text.heading', 2, { level: 4 })"
                  data-testid="heading-4"
                  >{{ $t("h4") }}</a
                >
              </li>
              <li>
                <a
                  :class="{ 'is-active': isActive.heading({ level: 5 }) }"
                  href="javascript:void(0)"
                  @click.prevent="commands.heading({ level: 5 })"
                  :title="$tc('rich_text.heading', 2, { level: 5 })"
                  data-testid="heading-5"
                  >{{ $t("h5") }}</a
                >
              </li>
              <li>
                <a
                  :class="{ 'is-active': isActive.heading({ level: 6 }) }"
                  href="javascript:void(0)"
                  @click.prevent="commands.heading({ level: 6 })"
                  :title="$tc('rich_text.heading', 2, { level: 6 })"
                  data-testid="heading-6"
                  >{{ $t("h6") }}</a
                >
              </li>
            </ul>
          </div>
          <!-- Table -->
          <div class="btn-group dropdown">
            <button
              v-if="!isActive.table()"
              class="btn btn-default"
              @click="
                commands.createTable({
                  rowsCount: 2,
                  colsCount: 2,
                  withHeaderRow: false
                })
              "
              :title="$t('rich_text.table')"
              data-testid="table"
            >
              <span class="fa fa-table"></span>
            </button>
            <button
              v-else
              class="btn btn-primary dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              :title="$t('rich_text.table')"
              data-testid="table"
            >
              <span class="fa fa-table" style="margin-right: 0.3em"></span>
              <span class="caret"></span>
            </button>
            <ul
              class="dropdown-menu dropdown-inline dropdown-menu-right first-row"
              v-if="isActive.table()"
            >
              <li>
                <a
                  href="javascript:void(0)"
                  @click.prevent="commands.deleteTable()"
                  :title="$t('rich_text.delete_table')"
                  ><img
                    src="/static/common/images/delete-table.svg"
                    :alt="$t('rich_text.delete_table')"
                /></a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click.prevent="commands.addColumnBefore()"
                  :title="$t('rich_text.add_column_before')"
                  ><img
                    src="/static/common/images/add-column-before.svg"
                    :alt="$t('rich_text.add_column_before')"
                /></a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click.prevent="commands.addColumnAfter()"
                  :title="$t('rich_text.add_column_after')"
                  ><img
                    src="/static/common/images/add-column-after.svg"
                    :alt="$t('rich_text.add_column_after')"
                /></a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click.prevent="commands.deleteColumn()"
                  :title="$t('rich_text.delete_column')"
                  ><img
                    src="/static/common/images/delete-column.svg"
                    :alt="$t('rich_text.delete_column')"
                /></a>
              </li>
            </ul>
            <ul
              class="dropdown-menu dropdown-inline dropdown-menu-right second-row"
              v-if="isActive.table()"
            >
              <li>
                <a
                  href="javascript:void(0)"
                  @click.prevent="commands.addRowBefore()"
                  :title="$t('rich_text.add_row_before')"
                  ><img
                    src="/static/common/images/add-row-before.svg"
                    :alt="$t('rich_text.add_row_before')"
                /></a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click.prevent="commands.addRowAfter()"
                  :title="$t('rich_text.add_row_after')"
                  ><img
                    src="/static/common/images/add-row-after.svg"
                    :alt="$t('rich_text.add_row_after')"
                /></a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click.prevent="commands.deleteRow()"
                  :title="$t('rich_text.delete_row')"
                  ><img
                    src="/static/common/images/delete-row.svg"
                    :alt="$t('rich_text.delete_row')"
                /></a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click.prevent="commands.toggleCellMerge()"
                  :title="$t('rich_text.toggle_cell_merge')"
                  ><img
                    src="/static/common/images/merge-cells.svg"
                    :alt="$t('rich_text.toggle_cell_merge')"
                /></a>
              </li>
            </ul>
          </div>
          <IconLibrary
            class="btn-group"
            @input="(name) => commands.icon({ name })"
          >
            <template #button="{ toggle }">
              <button
                class="btn btn-default"
                @click="toggle"
                :title="$t('rich_text.icon')"
                data-testid="icon"
              >
                <span class="glyphicon glyphicon-th-large"></span>
              </button>
            </template>
          </IconLibrary>
        </div>
        <div class="btn-group btn-group-justified" role="group">
          <!-- Bullet List -->
          <div class="btn-group">
            <button
              :class="[
                'btn',
                isActive.bullet_list() ? 'btn-primary' : 'btn-default'
              ]"
              @click="commands.bullet_list"
              :title="$t('rich_text.bullet_list')"
              data-testid="bullet-list"
            >
              <span class="fa fa-list-ul"></span>
            </button>
          </div>
          <!-- Ordered List -->
          <div class="btn-group">
            <button
              :class="[
                'btn',
                isActive.ordered_list() ? 'btn-primary' : 'btn-default'
              ]"
              @click="commands.ordered_list"
              :title="$t('rich_text.ordered_list')"
              data-testid="ordered-list"
            >
              <span class="fa fa-list-ol"></span>
            </button>
          </div>
          <!-- Undo -->
          <div class="btn-group">
            <button
              class="btn btn-default"
              @click="commands.undo"
              :title="$t('rich_text.undo')"
              data-testid="undo"
            >
              <span class="fa fa-undo"></span>
            </button>
          </div>
          <!-- Redo -->
          <div class="btn-group">
            <button
              class="btn btn-default"
              @click="commands.redo"
              :title="$t('rich_text.redo')"
              data-testid="redo"
            >
              <span class="fa fa-repeat"></span>
            </button>
          </div>
          <!-- Options -->
          <div class="btn-group" role="group">
            <button
              type="button"
              class="btn btn-default dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span class="glyphicon glyphicon-option-vertical"></span>
            </button>
            <ul class="dropdown-menu dropdown-menu-right">
              <li>
                <a
                  href="javascript:void(0)"
                  @click.prevent="isSimpleText = !isSimpleText"
                  data-testid="simple-text"
                >
                  <span class="fa fa-check" v-if="isSimpleText"></span>
                  {{ $t("rich_text.simple_text") }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </editor-menu-bar>
    <editor-content
      :class="['editor-content', { 'plain-text': editorPlainText }]"
      :editor="editor"
      v-show="!isPlainMode && !isSimpleText"
      :style="style"
      data-testid="content"
      ref="editorContent"
    />
    <textarea
      id="plain-text"
      rows="3"
      :class="['form-control', { 'simple-text': isSimpleText }]"
      :value="value"
      @input="update"
      v-show="isPlainMode || isSimpleText"
      data-testid="plain-content"
    ></textarea>
    <span
      v-if="!isSimpleText && !hasHTMLTags"
      id="toggle-mode"
      :class="['fa fa-font', { 'rich-text': !isPlainMode }]"
      @click="togglePlainMode"
      :title="$tc('rich_text.advanced_editing', +isPlainMode)"
      data-testid="toggle-mode"
    ></span>
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Bold,
  Italic,
  Underline,
  Strike,
  Heading,
  Blockquote,
  HardBreak,
  ListItem,
  BulletList,
  OrderedList,
  History,
  Table,
  TableCell,
  TableHeader,
  TableRow
} from "tiptap-extensions";
import { Color, Icon } from "@/utils/tiptap-extensions";
import ColorPicker from "./color-picker";
import IconLibrary from "./icon-library";

import pick from "lodash/pick";
import { whichTransitionEvent } from "@/utils";

export default {
  name: "RichText",
  props: {
    value: {
      type: String,
      required: false,
      default: ""
    },
    contentStyle: {
      type: Object,
      required: false,
      default: () => ({})
    },
    mode: {
      type: String,
      default: "rich",
      required: false,
      validator(val) {
        return ["rich", "plain"].includes(val);
      }
    },
    tableClass: {
      type: String,
      required: false,
      default: "static-table"
    }
  },
  components: {
    EditorContent,
    EditorMenuBar,
    ColorPicker,
    IconLibrary
  },
  data() {
    return {
      editor: new Editor({
        onUpdate: ({ getHTML }) => {
          this.localUpdate = true;
          this.$emit("input", this.parseHTML(getHTML()));
        },
        content: this.value,
        extensions: [
          new Bold(),
          new Italic(),
          new Underline(),
          new Strike(),
          new Heading({
            levels: [1, 2, 3, 4, 5, 6]
          }),
          new Blockquote(),
          new HardBreak(),
          new ListItem(),
          new BulletList(),
          new OrderedList(),
          new History(),
          new Table({ resizable: true }),
          new TableCell(),
          new TableHeader(),
          new TableRow(),
          new Color(),
          new Icon()
        ]
      }),
      // content is edited raw, but EditorMenu is visible (activated on option menu)
      isSimpleText: false,
      // content is edited raw without EditorMenu being visible
      isPlainMode: this.mode == "plain",
      HTMLTagsRegEx: /<(.*\/|[A-Za-z]+\d*)>/,
      localUpdate: false,
      editorPlainText: this.mode == "plain"
    };
  },
  computed: {
    style() {
      return pick(this.contentStyle, [
        "color",
        "text-align",
        "font-family",
        "font-size"
      ]);
    },
    hasHTMLTags() {
      return this.HTMLTagsRegEx.test(this.value);
    }
  },
  watch: {
    isPlainMode(val) {
      if (!val) {
        this.editor.setContent(this.value);
      }
      this.$emit("mode:update", val ? "plain" : "rich");
    },
    value: {
      immediate: true,
      handler(val) {
        if (!this.localUpdate && val) {
          this.isPlainMode = this.editorPlainText = !this.HTMLTagsRegEx.test(
            this.value
          );
          if (this.isPlainMode) {
            this.isSimpleText = false;
          } else {
            this.editor.setContent(val);
            // immediately updates current content
            // fixing any possible compatibility issues
            let parsedContent = this.parseHTML(this.editor.getHTML());
            if (parsedContent != val) {
              this.$nextTick(() => {
                this.localUpdate = true;
                this.$emit("input", parsedContent);
              });
            }
          }
        }
        this.localUpdate = false;
      }
    },
    mode(n) {
      this.isPlainMode = this.editorPlainText = n == "plain";
    }
  },
  methods: {
    update({ target: { value } }) {
      let content = value.replace(/\n/g, "");
      this.localUpdate = true;
      this.$emit("input", content);
      // sync update if is in simple text form
      if (this.isSimpleText) this.editor.setContent(content);
    },
    togglePlainMode() {
      // change to rich mode
      if (this.isPlainMode) {
        this.isPlainMode = false;
        this.$nextTick(() => {
          this.editorPlainText = false;
        });
      } else {
        // change to plain mode
        this.editorPlainText = true;
        this.isSimpleText = false;
      }
    },
    parseHTML(html) {
      return html
        .replace(/data-colwidth="(\d+)"/g, 'style="width: $1px"')
        .replace(
          /<table>(.+)<\/table>/g,
          `<table class="${this.tableClass}">$1</table>`
        )
        .replace(
          /<td( data-colwidth="(\d+)")?><p><\/p><\/td>/g,
          "<td$1><p><br></p></td>"
        );
    }
  },
  mounted() {
    if (this.$refs.editorContent && this.$refs.editorContent.$el) {
      this.$refs.editorContent.$el.addEventListener(
        whichTransitionEvent(),
        () => (this.isPlainMode = this.editorPlainText)
      );
    }
  },
  beforeDestroy() {
    this.editor.destroy();
  }
};
</script>
<style lang="scss" scoped>
.dropdown-menu {
  &.dropdown-inline {
    padding: 2px;
    min-width: max-content;

    &.first-row {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.second-row {
      margin-top: 32px;
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    li {
      display: inline-block;
      a {
        padding-left: 10px;
        padding-right: 10px;

        .fa {
          margin-right: 0;
        }
      }
    }

    img {
      max-width: 14px;
    }
  }

  .is-active,
  .is-active:hover {
    background-color: #3c8dbc;
    color: white;
  }
}
.btn-group.dropdown .btn {
  padding-left: 6px;
}
#plain-text {
  resize: vertical;
  position: relative;
  min-height: 74px;
  transition: top 300ms ease;
  border-radius: 4px;
  top: -55px;

  &.simple-text {
    transition: top 300ms;
    top: 0;
    border-radius: 0px 0px 4px 4px;
  }
}

#toggle-mode {
  color: #777;
  font-size: 0.9em;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 4px;

  &:hover {
    color: #5d5d5d;
  }

  &.rich-text {
    top: 55px;
  }
}
</style>
<style lang="scss">
.editor-content {
  position: relative;
  transition: top 300ms;
  top: 0;

  &.plain-text {
    transition: top 300ms;
    top: -54px;

    .ProseMirror {
      border-radius: 4px;
    }
  }

  .ProseMirror {
    display: block;
    width: 100%;
    min-height: 74px;
    padding: 6px 12px;
    line-height: 1.42857143;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow: auto;

    &:focus {
      outline: none;
    }

    &.resize-cursor {
      cursor: col-resize;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: 0;
      width: 4px;
      z-index: 20;
      background-color: #adf;
      pointer-events: none;
    }

    .fa.ProseMirror-selectednode,
    .glyphicon.ProseMirror-selectednode {
      background-color: #d1e5f3;
    }
  }
}

.editor-menu {
  margin-right: 5px;

  .btn-group:not(.dropdown) {
    .btn {
      padding-top: 2px;
      padding-bottom: 2px;

      &.dropdown-toggle {
        padding-left: 9px;
      }

      .fa {
        font-size: 0.8em;
      }
      .glyphicon {
        font-size: 0.9em;
      }
    }
  }

  // Sharp edges for outermost buttons
  .btn-group-justified {
    .btn-group:first-child {
      & > .btn:first-child {
        border-bottom-left-radius: 0;
      }
    }

    .btn-group:last-child {
      & > .btn:first-child {
        border-bottom-right-radius: 0;
      }
    }

    &:last-child {
      .btn-group:first-child {
        & > .btn:first-child {
          border-top-left-radius: 0;
        }
      }

      .btn-group:last-child {
        & > .btn:first-child {
          border-top-right-radius: 0;
        }
      }
    }
  }
}

.display-static-label-box table,
.editor-content .ProseMirror table {
  width: 100%;
  max-width: 100%;

  & > tbody {
    & > tr > th {
      vertical-align: bottom;
    }

    & > tr > th,
    & > tr > td {
      padding: 8px;
      line-height: 1.42857143;
      vertical-align: top;
      border: 2px solid #ddd;
      position: relative;
    }

    .selectedCell {
      background-color: #cddce042;
    }
  }
}
</style>
